<template>
  <div class="animated">
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("logList") }}
      </b-card-header>
      <b-card-body>
        <v-server-table
          :columns="columns"
          ref="table"
          :options="options"
          id="dataTable"
          class="log-grid"
        >
          <template slot="audit" slot-scope="props">
            <div
              :class="
                props.row.audit === 'ERROR'
                  ? 'errorText'
                  : props.row.audit === 'WARN'
                  ? 'warningText'
                  : 'infoText'
              "
            >
              {{ props.row.audit }}
            </div>
          </template>

          <div slot="child_row" slot-scope="props">
            <h3>{{ $t("details") }}</h3>
            <hr />
            <json-tree :raw="props.row.detail"></json-tree>
          </div>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import moment from "moment";
import http from "../../shared/http";
import { ServerTable } from "vue-tables-2";
import dateHelper from "../../shared/date-helper";
import utility from "../../shared/utils/screen-helper";
import JsonTree from "vue-json-tree";

export default {
  name: "LogTableComponent",
  components: {
    ServerTable,
    JsonTree,
  },
  props: {
    filters: {
      default: {
        fromCreatedAt: null,
        toCreatedAt: null,
        auditType: null,
        userId: null,
      },
      type: Object,
      required: false,
    },
  },
  methods: {
    getList() {
      this.$refs.table.setPage(1);
    },
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  data() {
    return {
      columns: ["audit", "userName", "createdAt", "methodName", "className"],
      options: {
        ...gridOptions.getAll(),
        perPageValues: [10, 25, 50, 100],
        requestFunction: (data) => {
          let path = "logs/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "createdAt";
            data.ascending = 0;
          }
          if (this.filters) {
            data.fromCreatedAt = dateHelper.getFromDateFormat(
              this.filters.fromCreatedAt
            );
            data.toCreatedAt = dateHelper.getToDateFormat(
              this.filters.toCreatedAt
            );
            data.auditType = this.filters.auditType;
            data.userId = this.filters.userId;
          }

          return http
            .get(path, { params: data })
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .finally(() => {
              utility.scrollToTop();
            });
        },
        sortable: "createdAt,className,methodName,userName,audit",
        headings: {
          audit: this.$t("logType"),
          userName: this.$t("user"),
          methodName: this.$t("methodName"),
          className: this.$t("className"),
          createdAt: this.$t("createdAt"),
        },
        templates: {
          createdAt: (h, row) =>
            moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.errorText {
  color: white;
  background: #f86c6b !important;
  padding: 7px;
  text-align: center;
  max-width: 100px;
}
.infoText {
  background: #63c2de;
  color: white;
  width: 100px;
  padding: 5px;
  text-align: center;
}
.warningText {
  background: #e09248;
  color: white;
  width: 100px;
  padding: 5px;
  text-align: center;
}
</style>

