<template>
  <div class="animated">
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-filter mr-1"></i>
        {{ $t("filter") }}
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label for="user">{{ $t("user") }}</label>
              <users-dropdown
                id="userId"
                :userid="filterObject.userId"
                @change="userChanged"
              ></users-dropdown>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="auditType">{{ $t("logType") }}</label>
              <b-form-select
                id="auditType"
                v-model="filterObject.auditType"
                :options="auditTypeOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <DateBetweenPicker
              :from="filterObject.fromCreatedAt"
              :to="filterObject.toCreatedAt"
              @change="dateChanged"
            ></DateBetweenPicker>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <LogTableComponent
      :filters="filterObject"
      v-if="$auth.hasAuth($options, 'list')"
    ></LogTableComponent>
  </div>
</template>

<<<<<<< HEAD ======= >>>>>>> bc5ad4dcfe9cd091346695391bd2aaae7c5266b4
<script>
import UsersDropdown from "../../widgets/UsersDropdown";
import DateBetweenPicker from "../../widgets/form/DateBetweenPicker";
import LogTableComponent from "../../widgets/log/LogTable";

export default {
  name: "LogList",
  components: {
    UsersDropdown,
    DateBetweenPicker,
    LogTableComponent,
  },
  methods: {
    userChanged(user) {
      this.filterObject.userId = user?.id ?? null;
    },
    dateChanged(dates) {
      this.filterObject.fromCreatedAt = dates?.from ?? null;
      this.filterObject.toCreatedAt = dates?.to ?? null;
    },
  },
  data() {
    return {
      auditTypeOptions: [
        { value: null, text: this.$t("all") },
        { value: "ERROR", text: "ERROR" },
        { value: "INFO", text: "INFO" },
        { value: "WARN", text: "WARN" },
      ],
      filterObject: {
        auditType: null,
        userId: null,
        fromCreatedAt: null,
        toCreatedAt: null,
      },
    };
  },
};
</script>
